// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery,useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

// local imports
import { styles } from './CreditContract.style';

function CreditContract(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const bars = useMediaQuery('(min-width:1300)');
    const firstDots = useMediaQuery('(min-width:900px)');
    const mobileBreakpoint = useMediaQuery('(min-width:450px)');

    //const mobileBreakpoint = useMediaQuery('(min-width:250px)');

    const blueBreak = useMediaQuery('(min-width:560px)');

    const mobileStart = useMediaQuery('(max-width:701px)');

    const theme = useTheme();
    const normalDots = useMediaQuery(theme.breakpoints.up(1151));
    const mediumDots = useMediaQuery(theme.breakpoints.between(900,1151));
    const smallerDots = useMediaQuery(theme.breakpoints.between(750,900));
    const inBetweenSize = useMediaQuery(theme.breakpoints.between(640,750));
    const reallySmall = useMediaQuery(theme.breakpoints.between(510,640));
    const smallest = useMediaQuery(theme.breakpoints.down(510));

    return(
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Box className={classes.titleContainer}>
                    <p className={classes.title}>Contrato de</p>
                    <p id='bot' className={classes.title}>apertura de crédito</p>
                </Box>
                <Box className={classes.section}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.subtitle}>
                            Contrato de apertura de crédito en cuenta corriente
                            con expedición de tarjetas de crédito, que celebran,
                             por una parte, Tarjetas Cuenca, S.A de C.V., en lo
                             sucesivo "Tarjetas Cuenca" y por la otra parte, la
                             persona cuyo nombre y datos constan en la
                             solicitud de este contrato, en lo sucesivo y
                             conjuntamente con "Tarjetas Cuenca" como "las
                             partes", al tenor de las siguientes:
                        </p>
                    </Box>
                </Box>
                <Grid container className={classes.declarationsContainer}>
                    <Grid item xs={mobileStart?12:4}>
                        <p className={classes.subtitleGrid}>
                            Declaraciones
                        </p>
                    </Grid>
                    <Grid item xs={mobileStart?12:8}>
                        <p className={classes.declaration}>
                            I. Declara el Cliente que:
                        </p>
                        <p className={classes.text}>
                            a) Es una Persona física en pleno uso y goce de sus
                            facultades para la celebración del presente
                            contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            b) La información y documentación que ha entregado a
                            Tarjetas Cuenca es verídica y representan en forma
                            veraz la solvencia económica necesaria para la
                            celebración del presente Contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            c) Los recursos que utilizará para pagar las
                            disposiciones realizadas del presente crédito
                            mediante la tarjeta de crédito son de su propiedad
                            y no son ni serán de procedencia ilícita.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            d) Leyó los datos que asentó en la Carátula, misma
                            que forma parte integrante del presente Contrato,
                            los cuales son correctos, por lo que autoriza a
                            Tarjetas Cuenca para corroborarlos en cualquier
                            momento, liberando a dicha entidad de cualquier
                            responsabilidad que pudiera surgir al respecto.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            e) Tarjetas Cuenca le informó a la firma del
                            presente Contrato, el contenido y alcance del mismo
                            y de la Carátula, así como los cargos o comisiones
                            que, en su caso, se generarán a partir de la firma
                            del presente instrumento y, en su caso, el Costo
                            Anual Total (CAT).
                        </p>
                        <hr />
                        <p className={classes.text}>
                            f) El Cliente autoriza que, en su modalidad inicial
                            y hasta que dicha situación cambie en términos de
                            lo previsto en el presente Contrato, el monto
                            disponible de la línea del crédito objeto del
                            presente estará relacionada con el saldo que
                            mantenga en su cuenta de fondos de pago electrónico
                            administrada por Cuenca Tecnología Financiera, S.A.
                            de CV. Institución de Fondos de Pago Electrónico.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            g) El Cliente declara que mediante la firma del
                            Aviso de Privacidad, ha otorgado las autorizaciones
                            necesarias para el intercambio de información entre
                            Tarjetas Cuenca y Cuenca Tecnología Financiera,
                            S.A. de C.V. Institución de Fondos de Pago
                            Electrónico, de conformidad con lo previsto por los
                            artículos 36 y 37 de la Ley General de Protección
                            de Datos Personales en Posesión de Particulares.
                        </p>
                        <hr />
                        <br /><br />
                        <br /><br />
                        <p className={classes.declaration}>
                            II. Declara Tarjetas Cuenca, a través de su
                            representante legal, que:
                        </p>
                        <p className={classes.text}>
                            a) Es una sociedad constituida y organizada
                            conforme a las leyes de los Estados Unidos Mexicanos.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            b) Cuenta con las autorizaciones y facultades
                            necesarias para la celebración del presente Contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            c) Su representante legal cuenta con las facultades
                            suficientes y necesarias para celebrar el presente
                            Contrato y para obligar a su representada en sus
                            términos, facultades que a la fecha no le han sido
                            revocadas, modificadas o limitadas en forma alguna.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            d) Su objeto social permite la celebración del
                            presente Contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            e) Considerando las declaraciones del Cliente, está
                            dispuesta a otorgarle una línea de crédito en los
                            términos establecidos en el presente Contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            f) Para todos los efectos legales a que haya lugar,
                            señala como su domicilio el ubicado en Varsovia
                            número 36, Piso 9, Oficina 912, Colonia Juárez,
                            Cuauhtémoc, C.P. 06600, Ciudad de México, México.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            g) Es su voluntad celebrar el presente Contrato.
                        </p>
                        <hr />
                    </Grid>
                    <p className={classes.text}>
                        De conformidad con las Declaraciones anteriores, las
                        partes se sujetan a lo que estipulan las siguientes:
                    </p>
                    <hr />
                </Grid>
                <Grid container className={classes.declarationsContainer}>
                    <Grid item xs={mobileStart?12:4}>
                        <p className={classes.subtitleGrid}>
                            Cláusulas
                        </p>
                    </Grid>
                    <Grid item xs={mobileStart?12:8}>
                        <p className={classes.clause}>
                            PRIMERA.-
                            <span className={classes.subclause}>
                              Definiciones.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Para efectos del presente Contrato, se entenderá por:
                            </span>
                        </p>
                        <p className={classes.text}>
                            a)	Aviso de Privacidad: al aviso de privacidad a
                            que se refiere la Ley General de Protección de
                            Datos de Datos Personales en Posesión de
                            Particulares, celebrado entre Tarjetas Cuenca S.A.
                            de C.V. y el Cliente, mismo que forma parte de los
                            documentos del Expediente del Cliente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            b)	Aviso de Cargos no Reconocidos: Al Aviso
                            presentado a Tarjetas Cuenca mediante el envío del
                            formato de reclamación del Cliente a Tarjetas
                            Cuenca, mismo que se adjunta al presente como
                            Anexo B.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            c)	Carátula: Al documento que se adjunta al
                            presente como Anexo A, en el cual se establecen,
                            entre otros datos, Monto del Crédito, plazos y
                            parcialidades, tasas de interés, CAT y, en su caso,
                            el monto de los cargos, comisiones y/o gastos
                            relacionados con el otorgamiento del crédito.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            d)	Cliente: persona cuyo nombre y datos se
                            precisan en la Carátula del presente Contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            e)	Contrato: el presente contrato de apertura de
                            crédito en cuenta corriente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            f)	Costo Anual Total (CAT): El Costo Anual Total
                            de financiamiento expresado en términos
                            porcentuales anuales que, para fines informativos
                            y de comparación, incorpora la totalidad de los
                            costos y gastos inherentes a los créditos.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            g)	Cuenca: Cuenca Tecnología Financiera, S.A. de
                            CV. Institución de Fondos de Pago Electrónico.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            h)	Cuenta de Pago: La cuenta de Fondos de Pago
                            Electrónico de la cual el Cliente es titular, y
                            que se encuentra registrada en Cuenca Tecnología
                            Financiera, S.A. de CV. Institución de Fondos de
                            Pago Electrónico.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            i)	Día Hábil Bancario: a los días en que las
                            instituciones de crédito no estén obligadas a
                            cerrar sus puertas ni a suspender operaciones,
                            en términos de las disposiciones de carácter
                            general que, para tal efecto, emita la Comisión
                            Nacional Bancaria y de Valores.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            j)	Domiciliación: En términos de la Ley para
                            Regular a las Instituciones de Tecnología
                            Financiera, a la ejecución de transferencias de
                            fondos o transferencias de fondos de pago
                            electrónico, sean individuales o recurrentes, con
                            cargo a una Cuenta de Fondos de Pago Electrónico,
                            que realice Cuenca de conformidad con la petición
                            expresa que el titular de dicha cuenta le presente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            k)	Expediente del Cliente: Conjunto de información
                            y/o documentos con datos personales del Cliente y
                            otra información y/o documentación relevante que,
                            de tiempo en tiempo se integre, así como su
                            actualización y/o modificación, que Tarjetas Cuenca
                            conserva a fin de cumplir con las disposiciones
                            normativas aplicables.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            l)	Factores de Autenticación:
                            i) Información que Tarjetas Cuenca le proporciona
                            al Cliente o permite a este generar, bajo el
                            entendido de que solamente dicha persona la
                            conozca, e
                            ii) Información contenida, recibida o generada por
                            medios o dispositivos electrónicos que solo posee
                            el Cliente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            m)	Fecha de Corte: El día de cada periodo mensual
                            en el cual concluirá el registro de operaciones
                            realizadas con la Tarjeta e iniciará el período
                            mensual siguiente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            n)	Fecha Límite de Pago: Fecha que el Estado de
                            Cuenta señalará como día límite para realizar el
                            pago del periodo mensual correspondiente. Cuando
                            dicha fecha se establezca en día inhábil bancario,
                            se recorrerá al próximo Día Hábil inmediato
                            siguiente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            o)	Fecha de Pago: Día en que se hace exigible la
                            obligación de restituir la cantidad adeudada por
                            parte del Cliente.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            p)	Tarjeta: Al medio de disposición plástico o
                            digital emitido por Tarjetas Cuenca a nombre del
                            Cliente para uso exclusivo por parte de este y para
                            hacer uso de la línea de crédito en los términos
                            previstos en el presente contrato.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            q)	Tarjetas Cuenca: Tarjetas Cuenca, S.A. de C.V.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            r)	Periodo: a los 30 días previos a la fecha de
                            corte.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            s)	Plataforma: A la interfaz interactiva para
                            teléfonos inteligentes que se encuentra
                            referenciada en la Aplicación, disponible para IOS
                            y Android de Cuenca por medio de la cual el Cliente
                            de Tarjetas Cuenca puede realizar:
                            i) Consultas de Saldos;
                            ii) Consultas de movimientos;
                            iii) Consultas de Estados de Cuenta;
                            iv) Consultas de la información de su cuenta, así
                            como cualquier otra funcionalidad que de tiempo en
                            tiempo implemente Tarjetas Cuenca.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            t)	Plataforma de Comunicación: aplicación de
                            mensajería para teléfonos inteligentes que envíe y
                            reciba mensajes mediante internet.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            u)	NIP: Número de Identificación Personal,
                            compuesto por una combinación numérica, mediante el
                            cual el Cliente podrá:<br />
                            i) Disponer del Monto del Crédito
                            ii) aceptar cambios en el presente Contrato;
                            iii) Darse por notificado de la información que
                            Tarjetas Cuenca le envié; y
                            iv) Expresar su consentimiento de cualquier mensaje
                            o documento que Tarjetas Cuenca le proporcioné.
                        </p>
                        <hr />
                        <p className={classes.text}>
                            v)	Monto del Crédito: Monto total de la línea de
                            Crédito ofrecido y autorizado por Tarjetas Cuenca
                            para ser dispuesto y destinado conforme al presente
                            Contrato, de conformidad con el esquema pactado
                            entre las partes.
                        </p>
                        <hr />
                        <br /><br />
                        <p className={classes.clause}>
                            SEGUNDA.-
                            <span className={classes.subclause}>
                              Objeto del Contrato y Apertura del Crédito.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El objeto del presente contrato es el otorgamiento
                              de un crédito, por lo que Tarjetas Cuenca abre al
                              Cliente una línea de crédito en cuenta corriente
                              en moneda nacional hasta por el importe que
                              determine Tarjetas Cuenca y que notifique al
                              Cliente y este acepte.
                            </span><br /><br />
                            <span className={classes.text}>
                              El otorgamiento del crédito será mediante alguno
                              de los dos esquemas siguientes:
                              i) Inicial: El importe máximo de la línea de
                              crédito iniciará relacionado al saldo que el
                              Cliente mantenga en su Cuenta de Fondos.
                              ii) Aumentado: Con base en el uso de la Tarjeta,
                              así como en la capacidad financiera del Cliente
                              para realizar los pagos y su perfil transaccional,
                              la línea del crédito podrá aumentarse por el monto
                              que determine Tarjetas Cuenca. El incremento de
                              dicha línea de crédito se le notificará al Cliente
                              para su aceptación.
                            </span><br /><br />
                            <span className={classes.text}>
                              A partir del primer año de operación, y a petición
                              del Cliente, este podrá realizar la solicitud de
                              contratación del esquema de crédito Aumentado y
                              Tarjetas Cuenca con base en los elementos antes
                              mencionados determinará si le aumenta el Monto
                              del Crédito. El Cliente deberá aceptar el monto
                              determinado.
                            </span><br /><br />
                            <span className={classes.text}>
                              El Cliente podrá realizar pagos en reembolso
                              parcial o total, según corresponda, de las
                              disposiciones que previamente hubiera hecho,
                              quedando facultado, mientras el presente contrato
                              esté vigente, para disponer del Monto del Crédito
                              autorizado por Tarjetas Cuenca.
                            </span><br /><br />
                            <span className={classes.text}>
                              Tarjetas Cuenca podrá restringir el uso de la
                              Línea del Crédito en cualquier momento durante la
                              vigencia del presente Contrato o disminuir la
                              misma, en ambos casos, notificándole al Cliente
                              por escrito mediante mensaje incluido a través
                              del correo electrónico, a través de la Plataforma
                              o a través de la Plataforma de Comunicación.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            TERCERA.-
                            <span className={classes.subclause}>
                              Expedición de las Tarjetas de Crédito y Número Confidencial.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Para disponer del crédito, Tarjetas Cuenca pondrá
                              a disposición del Cliente las Tarjetas.
                            </span><br /><br />
                            <span className={classes.text}>
                              La Tarjeta física se entregará desactivada al
                              Cliente en el domicilio establecido en la
                              Carátula, la Tarjeta física se activará mediante
                              el apartado de "Tarjeta Física" dentro de la
                              Plataforma. La Tarjeta digital será otorgada al
                              Cliente mediante la Plataforma, misma que se
                              encontrará desactivada hasta su activación dentro
                              de la Plataforma.
                            </span><br /><br />
                            <span className={classes.text}>
                              Tarjetas Cuenca al emitir las Tarjetas, asignará
                              a cada una de ellas un NIP, el cual única y
                              exclusivamente tendrá conocimiento el Cliente, el
                              cual podrá ser consultado por el Cliente mediante
                              la Plataforma.
                            </span><br /><br />
                            <span className={classes.text}>
                              El Cliente expresamente reconoce y acepta que las
                              Tarjetas son de uso personal e intransferible y
                              que el NIP y/o cualquier otro número confidencial
                              y/o contraseña que llegare a tener son
                              confidenciales, por lo que es su responsabilidad
                              mantener estricta seguridad en su almacenamiento.
                            </span><br /><br />
                            <span className={classes.text}>
                              El Cliente reconoce y acepta que serán de su
                              exclusiva responsabilidad las disposiciones que
                              se realizaren por el uso indebido que terceros no
                              autorizados llegaren a hacer de las Tarjetas, del
                              NIP o cualquier otro número confidencial y/o
                              contraseña que llegare a convenir con Tarjetas
                              Cuenca.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            CUARTA.-
                            <span className={classes.subclause}>
                              Formas de Disposición del Crédito.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Tarjetas Cuenca por cuenta y orden del Cliente y
                              con cargo al crédito materia de este contrato, se
                              obliga a pagar los importes de las disposiciones
                              que el Cliente realice como consecuencia o con
                              motivo de:
                            </span><br /><br />
                            <span className={classes.text}>
                              i. El pago de bienes y servicios en comercios con
                              cualquier de sus Tarjetas y dichas operaciones
                              sean autorizadas por el Cliente mediante el uso
                              de su NIP o cualquier otro factor de
                              autentificación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              ii. Las instrucciones de pago dadas a Tarjetas
                              Cuenca por el Cliente en favor de terceros, con
                              el objeto de cubrir adeudos periódicos o
                              recurrentes, por cualquier otro concepto.
                            </span><br /><hr />
                            <span className={classes.text}>
                              iii. El retiro de sumas de efectivo que haga el
                              Cliente a través de los Cajeros Automáticos que
                              pertenezcan a instituciones de crédito
                              establecidas en México o que estén afiliados a
                              los sistemas internacionales de emisores y
                              operadores de Tarjetas con los que Tarjetas
                              Cuenca tenga celebrados convenios, y<br />
                              (iii) en los establecimientos y comercios que
                              proporcionen el servicio de retiro de efectivo y
                              a través de las personas con las que Tarjetas
                              Cuenca celebre contratos de comisión mercantil.
                            </span><br /><hr />
                            <span className={classes.text}>
                              iv. Los cargos que Tarjetas Cuenca efectúe en la cuenta del crédito por concepto de intereses ordinarios, comisiones, gastos e impuestos a cargo del Cliente , de conformidad con lo estipulado en este Contrato. El Cliente autoriza en este acto a Tarjetas Cuenca para que realice en la referida cuenta corriente los cargos por los anteriores conceptos cuando el Cliente esté obligado a su pago.
                            </span><br /><hr />
                            <span className={classes.text}>
                              v. El Cliente podrá hacer uso del crédito en compras no presenciales o por medios electrónicos, a través de una Tarjeta. Dichas operaciones deberán ser autorizadas mediante el uso de Factores de Autenticación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              vi. La utilización de otras formas de disposición que Tarjetas Cuenca establezca autorice o acepte en el futuro.
                            </span><br /><hr />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            QUINTA.-
                            <span className={classes.subclause}>
                              Pago esquema Inicial.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Los pagos que el Cliente deba efectuar en el
                              esquema inicial, se deberán realizar mediante la
                              ejecución de las Domiciliaciones previamente
                              pactadas entre el Cliente y Cuenca en la Fecha de
                              Pago, es decir al día hábil siguiente en el que
                              se realice la operación.
                            </span><br /><br />
                            <span className={classes.text}>
                              Conforme a lo anterior y mediante la celebración
                              de las Domiciliaciones correspondientes
                              realizadas en su Cuenta de Pago, el Cliente
                              autoriza se le cargue el importe de todos los
                              cargos realizados con su Tarjeta en la Fecha de
                              Pago.
                            </span><br /><br />
                            <span className={classes.text}>
                              Para poder realizar disposiciones del crédito en
                              el esquema inicial, el Cliente deberá aceptar y
                              firmar el Anexo D del presente contrato.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            SEXTA.-
                            <span className={classes.subclause}>
                              Pago esquema Aumentado.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Los pagos que el Cliente deberá efectuar en el
                              esquema Aumentado, se deberán realizar mediante:
                              i) transferencia electrónica de fondos a la
                              Cuenta de Pago,
                              ii) transferencia electrónica de fondos a la
                              cuenta que Tarjetas Cuenca le indique, o
                              iii) mediante la ejecución de las Domiciliaciones
                              previamente pactadas entre el Cliente y Cuenca en
                              la Fecha de Pago.
                            </span><br /><br />
                            <span className={classes.text}>
                              El Cliente deberá realizar el pago en la Fecha
                              Límite de pago, los cargos que se hayan realizado
                              durante el Periodo de conformidad con la cláusula
                              "Composición del Saldo".
                            </span><br /><br />
                            <span className={classes.text}>
                              En este esquema, la Fecha Límite de Pago del
                              Cliente será dentro de los 10 (diez) días
                              naturales siguientes a la fecha de corte de su
                              Tarjeta de manera mensual, mismo que se
                              establecerá en la Carátula del presente contrato,
                              en el entendido de que cuando la Fecha de Pago
                              corresponda a un día inhábil el pago podrá
                              realizarse el Día Hábil siguiente, sin que
                              proceda el cobro de comisiones. En caso contrario
                              se generarán los intereses correspondientes.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            SÉPTIMA.-
                            <span className={classes.subclause}>
                              Composición del saldo.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              De conformidad con lo establecido en la cláusula anterior, el Cliente se obliga a restituir a Tarjetas Cuenca el saldo deudor a su cargo, en reembolso parcial o total de las disposiciones del crédito generadas durante el Periodo, y según corresponda conforme a las condiciones siguientes:
                            </span><br /><br />
                            <span className={classes.text}>
                              i. El saldo deudor en la cuenta corriente, en la Fecha de Corte que señala el Estado de Cuenta y la Carátula, del último Periodo, el cual estará compuesto por la suma de:
                            </span><br /><hr />
                            <span className={classes.text}>
                              a) El saldo correspondiente a la suma de los cargos realizados durante el periodo correspondiente. En caso de pagar el total de tu saldo, no se generarán intereses de ningún tipo.
                            </span><br /><hr className={classes.dottedLine} />
                            <span className={classes.text}>
                              b) Los intereses ordinarios, comisiones y gastos causados hasta la fecha de corte del último periodo.
                            </span><br /><hr className={classes.dottedLine} />
                            <span className={classes.text}>
                              c) El importe del Impuesto al Valor Agregado que cause el monto total de los intereses, comisiones y gastos generados hasta la fecha de corte del último Periodo.
                            </span><br /><hr className={classes.dottedLine} />
                            <span className={classes.text}>
                              d) En caso de aplicar, las promociones a meses con o sin
                              intereses.
                            </span><br /><hr className={classes.dottedLine} />
                            <br />
                            <span className={classes.text}>
                              Si el Cliente no desea pagar el saldo deudor completo, podrá pagar el saldo mínimo, el cual está compuesto del 5% del saldo deudor de la Tarjeta a la Fecha de Corte del periodo, más los Intereses Ordinarios, más los intereses moratorios, más el Impuesto al Valor Agregado (IVA), más el monto total de Retiro(s) en Efectivo, más el monto total de pagos a plazos futuros.
                            </span><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            OCTAVA.-
                            <span className={classes.subclause}>
                              Intereses en el esquema Aumentado.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              En caso de que el Cliente no pague el importe total del saldo deudor que hubiere a la fecha de corte del último periodo a más tardar en la Fecha Límite de Pago indicada en el estado de cuenta correspondiente a dicho periodo mensual el Cliente se obliga a pagar los siguientes intereses:
                            </span><br /><br />
                            <span className={classes.text}>
                              a) Intereses Ordinarios.- La tasa para este tipo se interés se indicará en la Carátula en términos anuales simples. Para calcular este tipo de intereses, se deberá multiplicar el saldo promedio de saldos insolutos por la tasa anual por el número de días naturales efectivamente transcurridos durante cada mes calendario y dividiendo el resultado entre 360.
                            </span><br /><hr />
                            <span className={classes.text}>
                              b) Intereses Moratorios.- En el supuesto de no cumplir con las obligaciones de pago establecidas en el presente en la Fecha de Pago deberás pagar intereses moratorios a partir del día siguiente sobre el saldo del pago vencido de cada periodo, adicional al del saldo insoluto, a la tasa que sea el resultado de aplicar hasta un porcentaje adicional del 80% (setenta y cinco por ciento) de la tasa de Interés Ordinario aplicable en los términos de la presente cláusula.
                            </span><br /><hr />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            NOVENA.-
                            <span className={classes.subclause}>
                              Aplicación de Pagos Parciales.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Los pagos que el Cliente realicé para abono en la Cuenta se aplicarán por Tarjetas Cuenca en el siguiente orden:
                            </span><br /><br />
                            <span className={classes.text}>
                              i. Impuestos aplicables,
                            </span><br /><hr />
                            <span className={classes.text}>
                              ii. Intereses moratorios,
                            </span><br /><hr />
                            <span className={classes.text}>
                              iii. Saldos vencidos,
                            </span><br /><hr />
                            <span className={classes.text}>
                              iv. Intereses Ordinarios,
                            </span><br /><hr />
                            <span className={classes.text}>
                              v. Saldo Insoluto periodos mensuales anteriores, y
                            </span><br /><hr />
                            <span className={classes.text}>
                              vi. Saldo Insoluto del último Periodo Mensual.
                            </span><br /><hr />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA.-
                            <span className={classes.subclause}>
                              Comisiones.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Tarjetas Cuenca no cobrará comisiones por la apertura del crédito ni disposiciones del mismo, únicamente cobrará las comisiones establecidas en el Anexo C del presente Contrato.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA PRIMERA.-
                            <span className={classes.subclause}>
                              Estados de Cuenta.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Las Partes acuerdan que Tarjetas Cuenca envíe un estado de cuenta de forma mensual, a la dirección de correo electrónico indicado en la Carátula del Contrato o mediante su consulta en la Aplicación, en donde le permita conocer los cargos realizados a su crédito. En el mismo sentido Tarjetas Cuenca tendrá disponible el estado de cuenta de los dos meses inmediatos anteriores en la sesión del Cliente en la Plataforma.
                            </span><br /><br />
                            <span className={classes.text}>
                              Los Estados de Cuenta deberán contener la información
                              siguiente:
                            </span><br /><br />
                            <span className={classes.text}>
                              i. Saldo inicial y el de la fecha de corte o del final del periodo;
                            </span><br /><hr />
                            <span className={classes.text}>
                              ii. El promedio de los saldos diarios del periodo;
                            </span><br /><hr />
                            <span className={classes.text}>
                              iii. Últimos dígitos de la Tarjeta;
                            </span><br /><hr />
                            <span className={classes.text}>
                              iv. Nombre del establecimiento comercial y lugar donde se utilizó la Tarjeta, solo si fuera el caso en que se hubiere utilizado la Tarjeta.
                            </span><br /><hr />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA SEGUNDA.-
                            <span className={classes.subclause}>
                              Objeciones de cargos.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Tarjetas Cuenca atenderá las solicitudes de objeciones de cargo no reconocidos, mismas que podrán ser presentadas dentro de un plazo de noventa Días Hábiles Bancarios contados a partir de la fecha en que se realizó el cargo respectivo. Dichas objeciones deberán presentarse bajo el formato establecido para el Aviso de Cargos No Reconocidos.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA TERCERA.-
                            <span className={classes.subclause}>
                              Procedencia de la objeción de cargos.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Cuando el Cliente objete algún cargo durante los primeros sesenta Días Hábiles Bancarios del plazo señalado en la cláusula inmediata anterior, Tarjetas Cuenca deberá abonar el monto reclamado a más tardar el Día Hábil Bancario siguiente a la recepción de la objeción, sin que se requiera del Cliente la realización de trámite adicional alguno.
                            </span><br /><br />
                            <span className={classes.text}>
                              Si la objeción se realiza entre el Día Hábil Bancario sesenta y uno y el Día Hábil Bancario noventa del plazo antes mencionado, Tarjetas Cuenca deberá resolver sobre la procedencia de la reclamación en un plazo máximo de veinte Días Hábiles Bancarios y, en caso de que la objeción resulte procedente, abonar el monto reclamado a más tardar el Día Hábil Bancario siguiente a la fecha de resolución.
                            </span><br /><br />
                            <span className={classes.text}>
                              El abono anterior no procederá cuando Tarjetas Cuenca, en términos de la cláusula décima cuarta, dentro del plazo mencionado, compruebe que la transacción fue autorizada mediante la utilización de Factores de Autenticación y mediante el uso del circuito integrado o chip de la Tarjeta Física o los datos de la Tarjeta Digital.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA CUARTA.-
                            <span className={classes.subclause}>
                              Improcedencia de la objeción de cargos.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              En caso de que la objeción de cargos no reconocidos no resulte procedente, Tarjetas Cuenca pondrá a disposición del Cliente la resolución en la que se expresen los argumentos que sustentan la improcedencia, así como copia de los documentos o evidencia de soporte respectivos, a través de la dirección de correo electrónico siguiente: <em>soporte@tarjetascuenca.com</em> o por medio de la Plataforma de Comunicación.
                            </span><br /><br />
                            <span className={classes.text}>
                              En cualquier caso, Tarjetas Cuenca enviará al Cliente dicha resolución, a través del correo electrónico que señale en la Carátula del presente Contrato.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA QUINTA.-
                            <span className={classes.subclause}>
                              Procedencia de los abonos realizados cargos no reconocidos.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Tarjetas Cuenca abonará, a más tardar el segundo Día Hábil Bancario contado a partir del día en que se haya presentado el aviso correspondiente, el monto equivalente a los cargos reclamados, siempre que no compruebe al Cliente mediante el dictamen correspondiente, que los cargos reclamados derivan de operaciones en las que, para su realización, se requirió al Cliente utilizar, al menos, dos elementos independientes para autenticar las operaciones como autorizadas por el mismo.
                            </span><br /><br />
                            <span className={classes.text}>
                              Dicho dictamen deberá contener, por lo menos:
                            </span><br /><br />
                            <span className={classes.text}>
                              i. Evidencia de los elementos de autenticación utilizados, así como la explicación, en lenguaje simple y claro, de los mismos y la forma en que la verificación de estos se realizó de acuerdo con los procedimientos aplicables a dichos Factores de Autenticación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              ii. Hora en la que se realizó la Operación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              iii. Nombre del receptor de pagos en donde se originó la Operación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              Los dos elementos deberán pertenecer a alguna de las siguientes categorías: a) Información que Tarjetas Cuenca proporciona al Cliente o permite a este generar, a efecto de que solamente él la conozca, para que la pueda ingresar al sistema Tarjetas Cuenca para iniciar la operación de que se trate, tales como contraseña o número de identificación personal; b) Información contenida, recibida o generada por medios o dispositivos electrónicos que solo posee el Cliente, incluyendo la almacenada en un circuito integrado o chip que sea procesada conforme a los estándares correspondientes, así como la obtenida por dispositivos generadores de contraseñas dinámicas que Tarjetas Cuenca proporcione a su Cliente. Lo anterior, siempre y cuando dicha información sea generada con propiedades que impidan su duplicación o alteración y que sea información dinámica que no pueda ser utilizada en más de una ocasión; y c) Información derivada de características propias del Cliente, como aquellas de carácter biométrico, incluyendo huellas dactilares, geometría de la mano o de la cara, patrones en iris o retina, entre otros.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA SEXTA.-
                            <span className={classes.subclause}>
                              Reversión de abonos realizados por Cargos no Reconocidos.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              En caso de que Tarjetas Cuenca compruebe que el cargo sujeto a reclamación o derivado de un aviso de extravío fue realizado por el Cliente, Tarjetas Cuenca podrá revertir el abono que hubiere realizado al Monto del Crédito.
                            </span><br /><br />
                            <span className={classes.text}>
                              Tarjetas Cuenca pondrá a disposición del Cliente, a través de la dirección de correo electrónico que señale el Cliente en la Carátula, dentro de un plazo de cuarenta y cinco Días Hábiles Bancarios contados a partir de la fecha en la que haya recibido el aviso correspondiente, el dictamen suscrito, que contenga la información siguiente: a) evidencia de los elementos de autenticación utilizados, así como la explicación, en que la verificación de estos se realizó de acuerdo con los procedimientos aplicables a dichos Factores de Autenticación; b) hora en la que se realizó la operación; c) nombre del receptor de pagos en donde se originó la operación; y c) en caso de contar con ella, la dirección física en la que se encuentra el dispositivo en el que se realizó la operación o, en su caso, dirección del protocolo de internet (IP, por sus siglas en inglés) a través de la cual dicho dispositivo haya estado conectado a internet.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA SÉPTIMA.-
                            <span className={classes.subclause}>
                              Aviso de robo o extravío de Tarjetas.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El Cliente podrá presentar, en cualquier día natural avisos de robo o extravío de la Tarjeta correspondiente, en cuyo caso Tarjetas Cuenca, deberá inhabilitar la Tarjeta. Para estos efectos los avisos se presentarán por medio de la Plataforma de Comunicación o a través de la dirección de correo: <em>soporte@tarjetascuenca.com</em>.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA OCTAVA.-
                            <span className={classes.subclause}>
                              Confidencialidad y Protección de Datos Personales.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El Cliente autoriza a Tarjetas Cuenca a utilizar su información personal para las cuestiones relacionadas con el cumplimiento del presente Contrato y así como para los demás fines establecidos en el Aviso de Privacidad.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            DÉCIMA NOVENA.-
                            <span className={classes.subclause}>
                              Rescisión del Contrato.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              En caso de que el Cliente no cumpla con alguna de las obligaciones que, en virtud de este Contrato, del uso, de la buena fe o de la ley son a su cargo. Tarjetas Cuenca podrá rescindir el presente Contrato sin necesidad de declaración judicial previa y sin responsabilidad alguna a su cargo, exigiendo el pago de los daños y perjuicios que correspondan, y sin perjuicio de las acciones judiciales que, en su caso, ejerza.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA.-
                            <span className={classes.subclause}>
                              Modificaciones.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El presente Contrato podrá ser modificado de tiempo en tiempo, total o parcialmente por Tarjetas Cuenca, siempre que esta última dé cumplimiento a los siguientes requisitos:
                            </span><br /><br />
                            <span className={classes.text}>
                              (i) Se notificará al Cliente con treinta días naturales de anticipación a la entrada en vigor mediante un mensaje enviado a través de la Plataforma. El mensaje contendrá información relativa a las modificaciones realizadas al Presente Contrato y se le invitará al Cliente a revisar los nuevos en nuestra página de internet <em>www.tarjetascuenca.com</em>, o, en su caso, de la Plataforma. El Cliente manifestará su consentimiento al aceptar los nuevos términos y condiciones del Contrato.
                            </span><br /><hr />
                            <span className={classes.text}>
                              (ii) Tarjetas Cuenca publicará en el siguiente vínculo <em>www.tarjetascuenca.com</em> un anuncio que contendrá:<br /><br />
                            </span><br /><hr className={classes.dottedLine} />
                            <span className={classes.text}>
                              (a) Un resumen de las modificaciones realizadas. Tratándose de modificaciones a las comisiones, tasas, rendimientos o descuentos, deberán señalarse las anteriores y las nuevas;
                            </span><br /><hr className={classes.dottedLine} />
                            <span className={classes.text}>
                              (b) Fecha de entrada en vigor, y
                            </span><br /><hr className={classes.dottedLine} />
                            <span className={classes.text}>
                              (c) Se informará al Cliente sobre el derecho de dar por terminado el presente Contrato. Para tal efecto, el Cliente, dentro de los treinta días naturales posteriores al mencionado aviso, podrá dar por terminado el mismo sin responsabilidad alguna a su cargo o penalización. Lo anterior no tendrá como consecuencia que el Cliente no deba cubrir el Saldo Deudor que se genere hasta la terminación efectiva del Contrato.
                            </span><br /><hr className={classes.dottedLine} />
                            <br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA PRIMERA.-
                            <span className={classes.subclause}>
                              Notificaciones.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Todas las notificaciones relacionadas con el presente Contrato se realizarán de Tarjetas Cuenca al Cliente a través: (i) en primer lugar por medio de la Plataforma; (ii) en segundo lugar en la dirección de correo electrónico otorgada por el Cliente en la Carátula del presente Contrato, y (iii) en tercer lugar al teléfono celular que otorgue el Cliente en la Carátula del presente contrato.
                            </span><br /><br />
                            <span className={classes.text}>
                              El Cliente notificará a Tarjetas Cuenca por medio de la dirección de correo electrónico siguiente: <em>soporte@tarjetascuenca.com</em> o por medio de la Plataforma de Comunicación.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA SEGUNDA.-
                            <span className={classes.subclause}>
                              Impuestos.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Los impuestos que resulten por la ejecución de las operaciones llevadas a cabo con base en el presente Contrato serán asumidos por las Partes, en términos de la legislación fiscal vigente.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA TERCERA.-
                            <span className={classes.subclause}>
                              Operaciones con recursos de procedencia ilícita.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Las partes se obligan a cumplir con las obligaciones que resulten a su cargo, en términos de lo establecido en las disposiciones aplicables en materia de Prevención e Identificación de operaciones con recursos de procedencia ilícita y financiamiento al terrorismo. El Cliente acepta que Tarjetas Cuenca bloquee Tarjeta por motivos de seguridad sin previa notificación, Tarjetas Cuenca se obliga a notificar en los dos primeros Días Hábiles Bancarios siguientes al bloqueo de Tarjeta del Cliente por medio de los medios establecidos en la cláusula Décima Novena.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA CUARTA.-
                            <span className={classes.subclause}>
                              Vigencia y Terminación Anticipada.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El presente Contrato tendrá una vigencia indefinida.
                            </span><br /><br />
                            <span className={classes.text}>
                              No obstante, el Cliente podrá dar por terminado
                              el presente Contrato en cualquier momento por medio de la
                              Plataforma, sin necesidad de rendir un informe o justificación
                              alguna sobre los motivos de dicho acto. En caso de que se hubieren
                              vinculado Tarjetas como medios de disposición, el Cliente deberá
                              manifestar bajo protesta de decir verdad, que no cuenta con ella o
                              que fue destruida.
                            </span><br /><br />
                            <span className={classes.text}>
                              Consecuentemente, Tarjetas Cuenca tendrá la obligación de proporcionar
                              al Cliente, a través de un mensaje en el correo electrónico proporcionado
                              por el Cliente, a más tardar al cierre del Día Hábil Bancario en el que
                              haya presentado la solicitud, un número de referencia de dicha solicitud
                              de terminación, especificando la hora y fecha en que se recibió.
                            </span><br /><br />
                            <span className={classes.text}>
                              En el caso previsto en esta cláusula Tarjetas Cuenca deberá:
                            </span><br /><br />
                            <span className={classes.text}>
                              i. Abstenerse de cobrar al Cliente cualquier tipo de comisión o
                              penalización por la terminación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              ii. Abstenerse de solicitar al Cliente justificación o motivo de
                              la terminación.
                            </span><br /><hr />
                            <span className={classes.text}>
                              iii. Cancelar, sin responsabilidad alguna, el cobro de algún
                              producto o servicio asociado desde la fecha de terminación del Contrato.
                            </span><br /><hr />
                            <span className={classes.text}>
                              iv. Abstenerse de condicionar la terminación a cualquier acto
                              no previsto en este Contrato.
                            </span><br /><hr />
                            <span className={classes.text}>
                              v. Cancelar todas Tarjetas emitidas a nombre de
                              Cliente.<br />
                              Adicionalmente el Cliente tiene derecho a dar por terminado el
                              presente Contrato dentro de un periodo de diez Días Hábiles
                              Bancarios posteriores a su aceptación. Dicha terminación no generará
                              responsabilidad de ningún tipo al Cliente. Por tal motivo Tarjetas Cuenca,
                              no podrá cobrar Comisión alguna, siempre que el Cliente no haya utilizado
                              u operado los servicios bajo el objeto del presente Contrato.
                            </span><br /><hr />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA QUINTA.-
                            <span className={classes.subclause}>
                              Cesión.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Las Partes no podrán ceder en todo o en parte los derechos y obligaciones que se derivan por la celebración de este Contrato.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA SEXTA.-
                            <span className={classes.subclause}>
                              Encabezados.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              Los encabezados de este Contrato son para realizar una ágil localización y por mera referencia, por lo que no se considerarán para efectos de su interpretación o cumplimiento.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA SÉPTIMA.-
                            <span className={classes.subclause}>
                              Acuerdo Íntegro.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El presente Contrato, el Aviso de Privacidad, los Términos y Condiciones y sus anexos, constituyen el acuerdo íntegro habido entre las Partes y sustituye cualquier otra negociación, acuerdo o convenio llevado al cabo con anterioridad.
                            </span><br /><br />
                        </p>
                        <br /><br />
                        <p className={classes.clause}>
                            VIGÉSIMA OCTAVA.-
                            <span className={classes.subclause}>
                              Legislación y Jurisdicción.
                            </span>
                            <span style={{ color: '#9BA0B7' }} className={classes.text}>
                              El presente Contrato se regirá e interpretará de conformidad con las leyes federales de México. Todas las controversias que deriven del presente Contrato, o que guarden relación con éste, serán resueltas por los tribunales competentes de la Ciudad de México, para lo cual las partes en este acto renuncian a cualquier otra jurisdicción que pudiera corresponderles por razón de sus domicilios presentes o futuros.
                            </span><br /><br />
                            <span className={classes.text}>
                              Enteradas las Partes del contenido, alcance y fuerza legal del presente Contrato, lo firman mediante el uso de firma electrónica por duplicado, en la Ciudad de México, el [*] de [*] de [*], a las [*] horas, correspondiendo un ejemplar junto con sus anexos para Tarjetas Cuenca y uno para el Cliente, cuya versión final se enviará al Cliente de manera electrónica vía correo electrónico.
                            </span>
                        </p>
                        <Grid container className={classes.subGridSignatures}>
                            <Grid item xs={6}>
                                <p className={classes.signatureStrong}>
                                    EL CLIENTE
                                </p><br />
                                <p className='signatureTextborderTop'>Firma</p><br />
                                <p className='signatureTextBottom'>Nombre:</p><br />
                            </Grid>
                            <Grid item xs={6}>
                                <p className={classes.signatureStrong}>
                                    TARJETAS CUENCA
                                </p><br />
                                <p className='signatureTextborderTop'>Firma</p>
                                <p className='signatureTextMiddle'>Nombre: Roberto Revilla Gama</p>
                                <p className='signature'>Cargo:
                                  <span className={classes.text}>
                                    &nbsp;&nbsp;Representante legal
                                  </span>
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box className={classes.annexContainer}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.subtitleGrid}>
                            Anexo A
                        </p>
                    </Box>
                </Box>
                <Grid container className={classes.subGridCommissions}>
                    <Grid item xs={4}>
                        <p className={classes.clause}>
                            CARÁTULA
                        </p>
                    </Grid>
                    <Grid item xs={8}>
                        <p className={classes.lightClause}>
                            Nombre comercial del Producto: Tarjeta Cuenca
                        </p>
                    </Grid>
                </Grid>
                {/* Table container */}
                <Box>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          CAT COSTO ANUAL&nbsp;TOTAL&nbsp;&nbsp;
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          TASA DE INTERÉS ANUAL ORDINARIA Y&nbsp;MORATORIA&nbsp;&nbsp;
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          MONTO O LÍNEA DE CRÉDITO
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          MONTO A PAGAR:
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.borderLeftBottom} style={{ verticalAlign: 'top' }}>
                                        <span className={classes.tableText}>
                                          $0.00
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLeftBottom}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Esquema inicial:</span>
                                        </span><br />
                                        <span className={classes.tableText}>
                                          Ordinaria: No aplica
                                        </span><br />
                                        <span className={classes.tableText}>
                                          Moratoria: No aplica
                                        </span><br /><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Esquema aumentado:</span>
                                        </span><br />
                                        <span className={classes.tableText}>
                                          Ordinaria: No aplica
                                        </span><br />
                                        <span className={classes.tableText}>
                                          Moratoria: 80% del<br />monto aplicable.
                                        </span><br />
                                    </TableCell>
                                    <TableCell className={classes.borderLeftBottom}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Esquema inicial:</span>
                                        </span><br />
                                        <span className={classes.tableText}>
                                          Referenciado al monto que el Cliente
                                          tenga en su Cuenca de Pago
                                        </span><br /><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Esquema aumentado:</span>
                                        </span><br />
                                        <span className={classes.tableText}>
                                          Por determinarse.
                                        </span><br />
                                    </TableCell>
                                    <TableCell rowSpan={2} className={classes.borderLessLastChild} style={{ borderBottom: '2.3px solid #3D4274' }}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Esquema inicial:</span>
                                        </span><br />
                                        <span className={classes.tableText}>
                                          No aplica, debido a que se<br />
                                          realizará una domiciliación<br />
                                          inmediata a la Cuenta de<br />
                                          Pago.<br /><br />
                                        </span><br /><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Esquema aumentado:</span>
                                        </span><br />
                                        <span className={classes.tableText}>
                                        De conformidad con los<br />
                                        términos que Tarjetas<br />
                                        Cuenca y el Cliente<br />
                                        acuerden.<br />
                                        </span><br />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} className={classes.borderLeftBottomBold}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Fecha límite de pago:</span>
                                        </span>
                                        <span className={classes.tableText}>
                                          Esquema inicial: Al momento de realizarse la domiciliación.
                                          Esquema aumentado: Fecha que se señale en el Estado de Cuenta.
                                        </span><br /><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Fecha de corte:</span>
                                        </span>
                                        <span className={classes.tableText}>
                                          Fecha que se indique en el Estado de Cuenta.
                                        </span><br /><br />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Table container */}
                <Box className={classes.annexTableContainer}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell  colSpan={4} className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          COMISIONES RELEVANTES
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell rowSpan={3} className={classes.borderLeftBottomVerticalAlign}>
                                        <span className={classes.tableText}>
                                          <span className='bold2'>Anualidad:</span>
                                        </span>
                                    </TableCell>
                                    <TableCell rowSpan={3} className={classes.borderLeftBottom} style={{ verticalAlign: 'top' }}>
                                        <span className={classes.tableText}>
                                          $0.00
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          <span className='bold2'>Reposición de tarjeta:</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          $250.00
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.borderLessBottom} style={{ paddingLeft: '16px' }}>
                                        <span className={classes.tableText}>
                                          <span className='bold2'>Envío de tarjeta:</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          $100.00
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.borderLessBottom} style={{ paddingLeft: '16px' }}>
                                        <span className={classes.tableText}>
                                          <span className='bold2'>Reclamación improcedente:</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          No aplica
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.borderLessBottom} style={{ paddingLeft: '16px' }}>
                                        <span className={classes.tableText}>
                                          <span className='bold2'>Retiro de efectivo:</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          Monto que determine la entidad
                                          propietaria del cajero.
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  colSpan={4} className={classes.borderLeftBottomBold} style={{ borderRight: '0px solid #3D4274' }}>
                                        <br />
                                        <span className={classes.tableTittle}>
                                          ADVERTENCIAS:
                                        </span>
                                        <br />
                                        <span className={classes.tableText}>
                                          "Contratar créditos que excedan su capacidad
                                          de pago afecta su historial crediticio".
                                        </span>
                                        <br />
                                        <br />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Table container */}
                <Box className={classes.annexTableContainer}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={4} className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          SEGUROS
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.borderLeftBottom}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Seguros sin costo
                                            adicion al: No aplica</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLeftBottom}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Aseguradora:
                                            No aplica</span>
                                        </span>
                                    </TableCell>
                                    <TableCell colSpan={2} className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          <span className='bold'>Cláusula: No aplica</span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.borderLessBottom}>
                                        <br />
                                        <span className={classes.tableTittle}>
                                          ESTADO DE CUENTA:
                                        </span>
                                        <br />
                                        <span className={classes.tableText}>
                                          Enviar a:
                                        </span>
                                        <br />
                                        <br />
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          Consulta vía Aplicación
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          SÍ
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.borderLessBottom}>
                                        <span className={classes.tableText}>
                                          Envío por correo electrónico
                                          al correo indicado en el
                                          proceso inicial.
                                        </span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} className={classes.borderBold}>
                                        <span className={classes.tableTittle}>
                                          ACLARACIONES Y RECLAMACIONES:
                                        </span><br /><br />
                                        <span className={classes.tableText}>
                                          Unidad de Atención a Usuarios:
                                        </span><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Domicilio:</span> Calle Varsovia 36, piso 9, oficina 912, Colonia Juárez, Ciudad de México.
                                        </span><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Teléfono:</span> 55 1328 5364, Correo Electrónico: <em>soporte@tarjetascuenca.com</em>
                                        </span><br />
                                        <span className={classes.tableText}>
                                          <span className='bold'>Página de Internet:</span> <em>www.tarjetascuenca.com</em>
                                        </span><br /><br /><br />
                                        <br />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} className={classes.borderLess}>
                                        <br />
                                        <br />
                                        <br />
                                        <span className={classes.tableText}>
                                          La presente carátula forma parte del
                                          contrato de crédito que documenta esta
                                          operación y el Cliente acepta contenido
                                          smediante la firma digital del mismo.
                                        </span><br />
                                        <br />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} className={classes.borderBold} style={{ textAlign: 'center' }}>
                                        <span className={classes.tableTittle}>
                                          NOMBRE Y FIRMA DEL CLIENTE
                                        </span><br /><br /><br /><br />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={classes.annexContainer}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.subtitleGrid}>
                            Anexo B
                        </p>
                    </Box>
                </Box>
                <Grid container className={classes.subGridCommissions}>
                    <Grid item xs={mobileStart?12:4}>
                        <p className={classes.clause2}>
                            AVISO DE CARGOS NO RECONOCIDOS
                        </p>
                    </Grid>
                    <Grid item xs={mobileStart?12:8}>
                        <p className={classes.text}>
                            <span style={{ float: 'right' }}>
                                <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                de <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                de 20<span className='under'>&emsp;&emsp;</span>.
                            </span>
                            <br />
                            Tarjetas Cuenca, S.A. de C.V.,
                            <br /><br />
                            Solicito a esa Sociedad la devolución del monto total de
                            $<span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                            por cargos realizados a partir del día <span className='under'>&emsp;&emsp;&emsp;</span>
                            de <span className='under'>&emsp;&emsp;&emsp;</span>  de 20<span className='under'>&emsp;&emsp;</span>
                            a mi Tarjeta identificada con el número (16 dígitos de la Tarjeta):
                            <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br /><br />
                            El número de identificación del cargo generado por el Tercero Autorizado es (dato no obligatorio):
                            <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br /><br />
                            <br /><br />
                            Al respecto, declaro que
                            (Marcar con una X la opción que corresponda):
                            <br /><br />
                            <ul style={{ listStyle: 'none', marginLeft: '0', paddingLeft: '2em', textIndent: '-2em' }}>
                              <li style={{ marginBottom: '0' }}>&#9634;&emsp;No autoricé el cargo.</li>
                              <li style={{ marginBottom: '0' }}>&#9634;&emsp;El importe del cargo excede el monto por periodo autorizado.</li>
                              <li style={{ marginBottom: '0' }}>&#9634;&emsp;El cargo se realizó indebidamente en más de una ocasión en el mismo periodo de facturación.</li>
                              <li style={{ marginBottom: '0' }}>&#9634;&emsp;La autorización fue cancelada con anterioridad a la realización del cargo, o</li>
                              <li style={{ marginBottom: '0' }}>&#9634;&emsp;Cualquier otro supuesto:
                              <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                              </li>
                            </ul>
                            <br /><br />
                            Estoy enterado de que si se resuelve en mi contra y esa Sociedad pretende
                            cobrar por la gestión, el cobro de la comisión no podrá exceder de: $200.00.
                            <br /><br />
                            Correo electrónico o número telefónico para recibir respuesta a la presente solicitud:
                            <br /><br /><br />
                            <p style={{ textAlign: 'center' }}>
                                <b>A t e n t a m e n t e,</b>
                                <br /><br />
                                <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                <br /><br />
                                <span>
                                    <b><i>(NOMBRE Y FIRMA AUTÓGRAFA DEL CLIENTE TITULAR DE LA TARJETA)</i></b>
                                </span>
                            </p>
                        </p>
                    </Grid>
                </Grid>
                <Box className={classes.annexContainer}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.subtitleGrid}>
                            Anexo C
                        </p>
                    </Box>
                </Box>
                <Grid container className={classes.subGridCommissions}>
                    <Grid item xs={mobileStart?12:4}>
                        <p className={classes.clause2}>
                            COMISIONES
                        </p>
                    </Grid>
                    <Grid item xs={mobileStart?12:8}>
                        <p className={classes.commissionTittle}>
                            TARJETA FÍSICA
                        </p>
                        <Box className={classes.commission}>
                            <Grid item xs={7} className='commissionText'>
                                Primera reposición
                                {normalDots || inBetweenSize  ? '...................................': null}
                                {mediumDots || reallySmall ? '.............................': null}
                                {smallerDots ? '...........................': null}
                                {smallest ? '........................' : null}
                            </Grid>
                            <Grid item xs={5} className={classes.rightNumber}>
                                <p className='blueSmallText'>$0</p>
                            </Grid>
                        </Box>
                        <Box className={classes.commission}>
                            <Grid item xs={7} className='commissionText'>
                                A partir de la segunda reposición
                                {normalDots || inBetweenSize  ?  '...........': null}
                                {mediumDots || reallySmall  ? '.....': null}
                                {smallerDots ? '...': null}
                                {smallest ? '' : null}
                            </Grid>
                            <Grid item xs={5} className={classes.blueContainer}>
                                <Box
                                    className={classes.rightNumber}
                                    style={{
                                        flexDirection: !blueBreak ? 'column' : 'row',
                                        alignItems: !blueBreak ? 'flex-start' : 'center',
                                    }}
                                >
                                    <p className='blueSmallText'>$250</p>
                                    <Box>

                                    </Box>
                                </Box>
                                <Box className={classes.bottomText}>
                                    La primera reposición de la tarjeta física es con nuestro
                                    diseño clásico azul. Para la segunda reposición, puedes
                                    elegir entre el diseño de edición especial y el clásico.
                                </Box>
                            </Grid>
                        </Box>
                        <Box className={classes.commission}>
                            <Grid item xs={7} className='commissionText'>
                                Edición especial
                                {normalDots || inBetweenSize ? '........................................': null}
                                  {mediumDots || reallySmall ? '.................................': null}
                                  {smallerDots ? '...............................': null}
                                {smallest ? '............................' : null}
                            </Grid>
                            <Grid item xs={5}  className={classes.blueContainer}>
                                <Box
                                    className={classes.rightNumber}
                                    style={{
                                        flexDirection: !blueBreak ? 'column' : 'row',
                                        alignItems: !blueBreak ? 'flex-start' : 'center',
                                    }}
                                >
                                    <p className='blueSmallText'>$250</p>
                                    <Box>

                                    </Box>
                                </Box>
                                <Box className={classes.bottomText}>
                                Las ediciones especiales de las tarjetas son parte de
                                los proyectos de colaboración de Cuenca con artistas y
                                artesanos, si quieres tener alguna de nuestras ediciones
                                especiales de la tarjeta física, debes comunicarte con Soporte.
                                </Box>
                            </Grid>
                        </Box>
                        <Box className={classes.commission}>
                            <Grid item xs={7} className='commissionText'>
                                Envío de tarjetas
                                {normalDots || inBetweenSize ? '........................................': null}
                                  {mediumDots || reallySmall ? '.................................': null}
                                  {smallerDots ? '...............................': null}
                                {smallest ? '............................' : null}
                            </Grid>
                            <Grid item xs={5}  className={classes.blueContainer}>
                                <Box
                                    className={classes.rightNumber}
                                    style={{
                                        flexDirection: !blueBreak ? 'column' : 'row',
                                        alignItems: !blueBreak ? 'flex-start' : 'center',
                                    }}
                                >
                                    <p className='blueSmallText'>$100</p>
                                    <Box>

                                    </Box>
                                </Box>
                                <Box className={classes.bottomText}>
                                Tarjetas Cuenca cobrará esta comisión por el envío
                                de la tarjeta física a cualquier parte de la República Mexicana.
                                </Box>
                            </Grid>
                        </Box>
                        <p className={classes.commissionTittle}>
                            COMISIONES POR RETIRO DE EFECTIVO
                        </p>
                        <Box className={classes.bottomText}>
                            Por retiro de efectivo en la red de cajeros electrónicos
                            de México y el resto del mundo, Cuenca no cobra comisión;
                            sin embargo, cada institución bancaria tiene  sus comisiones
                            por disposiciónd e efectivo
                        </Box>
                    </Grid>
                </Grid>
                <Box className={classes.annexContainer}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.subtitleGrid}>
                            Anexo D
                        </p>
                    </Box>
                </Box>

                <Grid container className={classes.subGridCommissions}>
                    <Grid item xs={mobileStart?12:4}>
                        <p className={classes.clause2}>
                            FORMATO PARA SOLICITAR LA CONTRATACIÓN DEL SERVICIO
                            DE DOMICILIACIÓN
                        </p>
                    </Grid>
                    <Grid item xs={mobileStart?12:8}>
                        <p className={classes.text}>
                          <u style={{ float: 'right' }}>
                              Fecha de firma del contrato de crédito
                          </u>
                          <br /><br />
                          Solicito y autorizo que con base en la información que
                          se indica en esta comunicación se realicen cargos en mi
                          Cuenta de Fondos de Pago Electrónico la cual mantengo
                          con Cuenca Tecnología Financiera, Institución de Fondos
                          de Pago Electrónico, conforme a lo siguiente:
                          <br /><br />
                          <ol>
                            <li style={{ marginBottom: '0' }}><u>Nombre del
                            Tercero Autorizado para instruir cargos en la Cuenta
                            de Fondos de Pago Electrónico:</u>Tarjetas Cuenca,
                            S.A. de C.V.
                            </li>
                            <li style={{ marginBottom: '0' }}><u>Motivo de la
                            Transferencia de Fondos o Transferencia de Fondos de
                            Pago Electrónico:</u> Pago del Crédito otorgado por
                            Tarjetas Cuenca.
                            </li>
                            <li style={{ marginBottom: '0' }}><u>Periodicidad del
                            cargo:</u> Cada vez que se utilice la Tarjeta.
                            </li>
                            <li style={{ marginBottom: '0' }}><u>Nombre de la
                            Institución del Cliente Domiciliado:</u> Cuenca
                            Tecnología Financiera, Institución de Fondos de Pago
                            Electónico.
                            </li>
                            <li style={{ marginBottom: '0' }}><u>Datos de
                            identificación de la Cuenta de Fondos de Pago
                            Electrónico:</u> Cuenta de Fondos de Pago electrónico
                            a nombre del Cliente.
                            </li>
                            <li style={{ marginBottom: '0' }}><u>Monto máximo
                            fijo del cargo autorizado por periodo de facturación:
                            </u> No aplica.
                            </li>
                            <li style={{ marginBottom: '0' }}><u>Esta autorización
                            es:</u> por plazo indeterminado.
                            </li>
                          </ol>
                          La presente domiciliación se celebra con base en los
                          acuerdos celebrados entre Cuenca Tecnología Financiera,
                          S.A. de C.V. y Tarjetas Cuenca, S.A. de C.V.
                          <br /><br />
                          Estoy enterado de que en cualquier momento podré
                          solicitar la cancelación de la presente Domiciliación.
                          <br /><br />
                          La presente Domiciliación forma parte del Contrato de
                          Crédito celebrada en el presente documento y el Cliente
                          acepta contenido mediante la firma digital del mismo.
                          <br /><br /><br />
                          <div className={classes.underSign}>&emsp;</div>
                          <span className='signature'>
                              (Firmado digitalmente)
                              <br />
                              (Nombre del cliente)
                          </span>
                          <br /><br />
                        </p>
                    </Grid>
                </Grid>

                <Box className={classes.annexContainer}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.subtitleGrid}>
                            Anexo E
                        </p>
                    </Box>
                </Box>

                <Grid container className={classes.subGridCommissions}>
                    <Grid item xs={mobileStart?12:4}>
                        <p className={classes.clause2}>
                            FORMATO PARA SOLICITAR LA CANCELACIÓN DEL SERVICIO DE DOMICILIACIÓN
                        </p>
                    </Grid>
                    <Grid item xs={mobileStart?12:8}>
                        <p className={classes.text}>
                            <span style={{ float: 'right' }}>
                                <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                de <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                de 20<span className='under'>&emsp;&emsp;</span>.
                            </span>
                            <br /><br />
                            Tarjetas Cuenca, S.A. de C.V.,
                            <br /><br />
                            1. Nombre del Tercero Autorizado para instruir cargos en la Cuenta de 
                            Fondos de Pago Electrónico: TARJETAS CUENCA, S.A. DE C.V.
                            <br /><br />
                            2. Motivo de la Transferencia de Fondos o Transferencia de Fondos de 
                            Pago Electrónico cuya Domiciliación se solicita cancelar: 
                            <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br /><br />
                            En su caso, el número de identificación generado por el Tercero 
                            Autorizado (dato no obligatorio): 
                            <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br /><br />
                            3. Cualquiera de los datos de identificación de la Cuenta de Fondos 
                            de Pago Electrónico donde se efectúa el cargo, siguientes:
                            <br /><br />
                            Número de Tarjeta (16 dígitos): 
                            <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>, o
                            <br /><br />
                            Clave Básica Estandarizada ("CLABE") de la Cuenta de Fondos de Pago 
                            Electrónico (18 dígitos): 
                            <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br /><br />
                            Estoy enterado de que la cancelación surtirá efectos en un plazo no 
                            mayor a 3 Días Hábiles Bancarios contado a partir de la fecha de 
                            presentación de esta solicitud.
                            <br /><br /><br />
                            <p style={{ textAlign: 'center' }}>
                                <span className='under'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                <br />
                                <b>A t e n t a m e n t e,</b>
                                <br /><br />
                                <span>
                                    <b><i>(NOMBRE Y FIRMA AUTÓGRAFA DEL CLIENTE TITULAR DE LA TARJETA)</i></b>
                                </span>
                            </p>
                        </p>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(CreditContract);
